import { useEffect, useState } from "react";
import "./productPage.scss";
import ProductApi from "../../../Api/Product";
import { Carousel, Collapse } from "antd";
const Product = () => {
  const { Panel } = Collapse;
  const [listProduct, setListProduct] = useState([]);
  const panelKeys = ['1', '2', '3'];
  const [activeKey, setActiveKey] = useState([panelKeys[0]]);

    const handleDropdownTimeOut = () => {
      return setInterval(() => {
          setActiveKey(prevActiveKey => {
              const currentIndex = panelKeys.indexOf(prevActiveKey[0]);
              const nextIndex = (currentIndex + 1) % panelKeys.length;
              return [panelKeys[nextIndex]];
          });
      }, 3000);
  };

  useEffect(() => {
      const intervalId = handleDropdownTimeOut();
      
      return () => clearInterval(intervalId); 
  }, []);
  const fetchProduct = async (config = {}) => {
    try {
      const response = await ProductApi.GetAll(config);
      setListProduct(response.data);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  const handlePanelChange = (keys) => {
    setActiveKey(keys);
  };

  return (
    <>
      <div className="wapperProduct wapperProductMobile">
        <div className="ProductLeft">
          <Carousel
           dots={false} 
           autoplay
           slidesToShow={1}
           effect="fade"
           >
            {listProduct.map((item, index) => (
              <div key={index}>
                <img
                  alt=""
                  className="imageProduct imageProductMobile"
                  src={item.product_image_url1}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="ProductRight styles.ProductRightMobile">       
          <Collapse
            activeKey={activeKey} 
           onChange={handlePanelChange}
           >
          {listProduct.map((item)=>(
            <Panel  header={item.product_title} key={item.product_id}>
              {item.product_body}
            </Panel>   
        ))}
          </Collapse>         
        </div>
      </div>
    </>
  );
};
export default Product;
