import GoTop from "../../Component/PageComponent/GoTop/GoTop";
import "./introduce.scss";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import { useCallback, useEffect, useState } from "react";
import AboutusApi from "../../Api/Aboutus";
import ContactChild from "../../Component/PageComponent/Contact/Contact";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { getCategory, getListMetaSeo } from "../../config/helper";
const Introduce = ({ targetRef }) => {
  const [listAboutus, setListAboutus] = useState([]);
  const [listAboutId, setListAboutId] = useState([]);
  const location = useLocation();
  const listCategory = useSelector((state) => state.menu.data);
  const listMetaSeo = getListMetaSeo(listCategory, location?.pathname.slice(1));
  const id = getCategory(listCategory, location?.pathname.slice(1));
  const FetchListAboutus = async (config = {}) => {
    try {
      const response = await AboutusApi.GetAll(config);
      setListAboutus(response?.data?.data.data[0]);
    } catch (err) {
      throw err;
    }
  };
  const FetchListAboutusId = useCallback(async () => {
    try {
      const response = await AboutusApi.GetlistAboutId(id, {});
      setListAboutId(response?.data?.data.data);
    } catch (err) {
      throw err;
    }
  }, [id]);
  useEffect(() => {
    FetchListAboutus();
  }, []);
  useEffect(() => {
    FetchListAboutusId();
  }, [FetchListAboutusId]);
  return (
    <>
      {listMetaSeo && (
        <Helmet>
          <title>{listMetaSeo?.category_meta_title}</title>
          <meta
            name="description"
            content={listMetaSeo?.category_meta_description}
          />
          <meta
            name="keywords"
            content={listMetaSeo?.category_meta_description}
          />
          <meta property="og:locale" content="" />
          <meta property="og:type" content="" />
          <meta property="og:description" content="" />
          <meta property="og:url" content="" />
          <meta property="og:site_name" content="" />
          <meta property="og:img" content="" />
        </Helmet>
      )}
      <div>
            <div>
              <div className="bannerIntroduce">
                <div className="bannerContent">
                  <h1 className="titleBannerPost">{listAboutus?.title}</h1>
                  <p className="textBannerPost">{listAboutus?.description} </p>
                </div>
              </div>
              <div className="bannerIntroduceTablet">
                <div className="bannerContentTablet">
                  <h1 className="titleBannerPostTablet">{listAboutus?.title}</h1>
                  <p className="textBannerPostTablet">{listAboutus?.description} </p>
                </div>
              </div>
              <div className="bannerIntroduceMobile">
                <div className="bannerContentMobile">
                  <h1 className="titleBannerPostMobile">{listAboutus?.title}</h1>
                  <p className="textBannerPostMobile">{listAboutus?.description} </p>
                </div>
              </div>
            </div>
        <div className="contatinerTitlePost contatinerTitlePostMobile">
          {listAboutus?.length &&
            listAboutus?.map((item, index) => (
              <h1 className="TitlePost TitlePostMobile" key={index}>
                {item?.core_values_title}
              </h1>
            ))}
        </div>

        <div className="PostIntroduce PostIntroduceMobile">
          <div className="containerLefft">
            {listAboutId?.length &&
              listAboutId?.map((item, index) => (
                <div key={index}>
                  <div className="contatinerPostIntroduce">
                    <h2 className="childTitle childTitleMobile">
                      {item?.name}
                    </h2>
                    <div className="postContent">
                      <div
                        className="contentPostText contentPostTextMobile"
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                      ></div>
                    </div>
                    <div>
                      <img alt="" src={item?.image_url} />
                    </div>
                  </div>
                  <div className="linePostIntroduce">
                    <hr />
                  </div>
                </div>
              ))}
          </div>
          
              <div className="containerRight containerRightMobile">
                <img alt="" src="https://carlink.com.vn/assets/img/carlink_about.jpg" />
              </div>
           

          {/* {listAboutus?.length &&
            listAboutus?.map((item) => (
              <div className="containerRight containerRightMobile">
                <img alt="" src={item?.about_image_url} />
              </div>
            ))} */}
        </div>
      </div>

      <FormRegister targetRef={targetRef} />
      <ContactChild />
      <GoTop />
    </>
  );
};
export default Introduce;
